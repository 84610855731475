// pages/index.tsx
import React from "react";
import UploadForm from "@/components/UploadForm";
import TitleComponent from "../components/Title";
import withAuth from "../hoc/withAuth";

const UploadPage: React.FC = () => {
  return (
    <>
      <UploadForm />
    </>
  );
};

export default withAuth(UploadPage);
